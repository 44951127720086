/*
 * Custom
 */
$( document ).ready(function() {

    // Scroll to

    $(".product_links li a").on('click', function(e) {
        e.preventDefault();
        var target = $(this).attr('href');
        $('html, body').animate({
            scrollTop: ($(target).offset().top)
        }, 800);
    });

    // Load more

    var $parent = $(".news_list_wrap"),
        $items = $parent.find(".news_list_item"),
        $loadMoreBtn = $("#loadMore"),
        maxItems = 10,
        hiddenClass = "visually-hidden";

    $.each($items, function(idx,item){
        if(idx > maxItems - 1){
            $(this).addClass(hiddenClass);
        }
    });

    var $parent = $(".events_list_wrap"),
        $items = $parent.find(".events_list_item"),
        $loadMoreBtn = $("#loadMore"),
        maxItems = 16,
        hiddenClass = "visually-hidden";

    $.each($items, function(idx,item){
        if(idx > maxItems - 1){
            $(this).addClass(hiddenClass);
        }
    });

    // if user submitted own data already, then show download materials
    if (getCookie('submittedInfo')){
        showVideo();
        showDownloadButtons();
    }

    // onclick of show more button show more = maxItems
    // if there are none left to show kill show more button
    $loadMoreBtn.on("click", function(e){
        $("."+hiddenClass).each(function(idx,item){
            if(idx < maxItems - 1){
                $(this).removeClass(hiddenClass);
            }
            // kill button if no more to show
            if($("."+hiddenClass).size() === 0){
                $loadMoreBtn.hide();
            }
        });
    });

    // Mobile menu

    $('.header_wrap__mobile a').on('click', function () {
       $('.header_wrap__nav').fadeToggle();
       $('.overlay').toggleClass('overlay_active');
    });

    $('.overlay').on('click', function () {
        $('.header_wrap__nav').fadeToggle();
        $(this).toggleClass('overlay_active');
    });

    // Submit form

    jQuery(document).on('gform_confirmation_loaded', function(event, formId){
        showVideo();
        showDownloadButtons();
        setCookie('submittedInfo', true)
    });

    // Menu hover

    $('.header_wrap__nav ul li').mouseenter(function() {
        $(this).children('.sub-menu').stop().fadeIn()
    }).mouseleave(function() {
        $(this).children('.sub-menu').stop().fadeOut()
    });

    // Modal

    $("#about_video").click(function(){
        $(".about_video").modal('show');
        $('.youtube-video')[0].contentWindow.postMessage('{"event":"command","func":"' + 'playVideo' + '","args":""}', '*');
    });
    $(".about_video").modal({
        closable: true,
        onHide: function(){
            $('.youtube-video')[0].contentWindow.postMessage('{"event":"command","func":"' + 'pauseVideo' + '","args":""}', '*');
        },
        onShow: function(){
            $('.youtube-video')[0].contentWindow.postMessage('{"event":"command","func":"' + 'playVideo' + '","args":""}', '*');
        }
    });

    $("#webinar").click(function(){
        $(".webinar").modal('show');

    });
    $(".webinar").modal({
        closable: true,

    });

    // functions for hide/show forms

    function showVideo(){
        $('.description__form').hide();
        $('.description__video').show();
    }

    function showDownloadButtons(){
        $('.disabled').removeClass('disabled');
        $('.resources_download__title').hide();
        $('.resources_download__form').hide();
    }

    // functions to work with cookies

    function setCookie(key, value) {
        var expires = new Date();
        expires.setTime(expires.getTime() + (1 * 24 * 60 * 60 * 1000));
        document.cookie = key + '=' + value + ';expires=' + expires.toUTCString();
    }

    function getCookie(key) {
        var keyValue = document.cookie.match('(^|;) ?' + key + '=([^;]*)(;|$)');
        return keyValue ? keyValue[2] : null;
    }

    // Scroll nav

    $(window).scroll(function() {
        var scroll = $(window).scrollTop();
        if (scroll >= 5) {
            $(".header").addClass("header__scroll");
        } else {
            $(".header").removeClass("header__scroll");
        }
    });

    // Team info

    $('.about_team_item').on('click', function () {
        $(this).toggleClass('about_team_item__info');
    });

    // Tabs

    $('ul.tabs li').click(function(){
        var tab_id = $(this).attr('data-tab');

        $('ul.tabs li').removeClass('current');
        $('.tab-content').removeClass('current');

        $(this).addClass('current');
        $("#"+tab_id).addClass('current');
    });

    // Tabs slider

    var swiper_tabs = new Swiper('.swiper-tabs', {
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
            clickable: true,
        },
        spaceBetween: 30,
        loop: true,
    });

    // Slider logos

    var swiper_logos = new Swiper('.swiper-logos', {
        slidesPerView: 4,
        loop: true,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
            clickable: true,

        },
        breakpoints: {
            768: {
                slidesPerView: 2
            }
        }
    });


    // Slider product

    var swiper = new Swiper('.swiper-product', {
        pagination: {
            el: '.swiper-pagination',
            clickable: true,

        },
    });

    // Cv input

    // document.querySelector("html").classList.add('js');
    //
    // var fileInput  = document.querySelector( "#cv" ),
    //     button     = document.querySelector( ".cv_input" ),
    //     the_return = document.querySelector(".file-return");
    //
    // button.addEventListener( "keydown", function( event ) {
    //     if ( event.keyCode == 13 || event.keyCode == 32 ) {
    //         fileInput.focus();
    //     }
    // });
    // button.addEventListener( "click", function( event ) {
    //     fileInput.focus();
    //     return false;
    // });
    // fileInput.addEventListener( "change", function( event ) {
    //     the_return.innerHTML = this.value;
    // });
});

// Full page

    // new fullpage('#fullpage', {
    //     licenseKey: '545BC969-E9874D5D-B18F3FC7-700157D1',
    //     scrollHorizontally: true,
    //     scrollOverflowOptions: { disablePointer: true, },
    //     scrollOverflow: true,
    //     responsiveHeight: 768,
    // });
    //
    // new fullpage('#fullpage_product', {
    //     licenseKey: '545BC969-E9874D5D-B18F3FC7-700157D1',
    //     scrollHorizontally: true,
    //     scrollOverflowOptions: { disablePointer: true, },
    //     lockAnchors: false,
    //     scrollOverflow: true,
    //     menu: '.myMenu',
    //     navigation: false,
    //     navigationPosition: 'left',
    //     responsiveHeight: 768,
    //
    // });
    //
    // fullpage_api.setRecordHistory(false);